import React, { useContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { storageKeys, common } from "../../utils/constants";
import globalContext from "../../context/globalContext";
import { getOrganizationDetail } from "../../utils/ApiClient";
import Slider from "react-slick";
import SplashSlide1 from '../../component/assests/images/slide1.png'
import SplashSlide2 from '../../component/assests/images/slide2.png'
import SplashSlide3 from '../../component/assests/images/slide3.png'
import SplashSlide4 from '../../component/assests/images/slide4.png'
import SplashSlide5 from '../../component/assests/images/slide5.png'
import SplashSlide6 from '../../component/assests/images/slide6.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SplashScreen.scss'

const SplashScreen: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const oId: string | null = searchParam.get("oid");
  const { globalErrorHandler }: any = useContext(globalContext);
  const navigate = useNavigate()
  const effectRef = useRef(false);

  const getEAPXOrganizationDetail = async (oId: string | null): Promise<void> => {
    try {
      const response: any = await getOrganizationDetail(oId);
      localStorage.setItem(storageKeys.COMPANY, response.data.data.name);
      localStorage.setItem(storageKeys.ORGANIZATION_ID, response.data.data.id);
    } catch (error: any) {
      globalErrorHandler(error);
      if (error.response?.status === 404) {
        setError(true);
      } else {
        navigate("/invalid-link");
      }
    }
  };

  useEffect(() => {
    if (effectRef.current === false) {
      getEAPXOrganizationDetail(oId);

    }
    return () => {
      effectRef.current = true;
    };
  }, [oId]);

  return (
    <div className='splash-screen-page'>
      {
        error ? <div className="loader-container">
          <span>{common.CONTCAT_SUPPORT}</span>
        </div> : <Slider {...settings}>
          <img src={SplashSlide1} alt='splash-image' />
          <img src={SplashSlide2} alt='splash-image' />
          <img src={SplashSlide3} alt='splash-image' />
          <img src={SplashSlide4} alt='splash-image' />
          <img src={SplashSlide5} alt='splash-image' />
          <img src={SplashSlide6} alt='splash-image' />
        </Slider>
      }
    </div>
  )
}

export default SplashScreen