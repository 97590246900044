import React from 'react'
import './CourseListItem.scss'
import CheckedIcon from '../../assests/icons/green-checked.png'

interface ListItemProps {
    title: string;
    image: string;
    duration: string;
    inProgress: boolean;
}

const CourseListItem: React.FC<ListItemProps> = ({ image, title, duration, inProgress }) => {
    return (
        <div className={`course-list-component ${inProgress ? "active-module" : ""}`}>
            {
                inProgress ? <img src={CheckedIcon} alt='icon' className='checked-icon-green' /> :
                    <div className='course-icon-box'>
                        <img src={image} alt='icon' className='course-list-icon' />
                    </div>
            }
            <div className='course-details'>
                <span className='course-item-title'>
                    {title}
                </span>
                <span className='course-item-duration'>{duration}</span>
            </div>
        </div>
    )
}

export default CourseListItem