import { calculateAge } from '../../../../utils/helperFunctions'

export const validate = (input: any): string[] => {
  function validateOrThrow(
    value: any,
    fieldName: string,
    fieldType: string
  ): string | undefined {
    if (fieldType === "string") {
      if (value === undefined || value === null || value.trim() === "") {
        return `${fieldName} is required.`;
      }
    } else if (fieldType === "number") {
      if (value === undefined || value === null || isNaN(value)) {
        return `${fieldName} must be a valid number.`;
      }
    }
  }

  const errors: string[] = [];

  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      const value: any = input[key as keyof typeof input];
      let fieldName: string;

      switch (key) {
        case "first_name":
          fieldName = "First Name";
          const firstNameError = validateOrThrow(value, fieldName, "string");
          if (firstNameError) {
            errors.push(firstNameError);
          }
          break;

        case "last_name":
          fieldName = "Last Name";
          const lastNameError = validateOrThrow(value, fieldName, "string");
          if (lastNameError) {
            errors.push(lastNameError);
          }
          break;

        case "dob":
          fieldName = "Date of Birth";
          if (!value || !/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            errors.push("Please enter a valid birth-date");
          } else {
            const dateParts = value.split('-'); 
            const year = dateParts[0]; 
            const month = dateParts[1]; 
            const day = dateParts[2];
            const birthDate = `${day}-${month}-${year}`
            const age = calculateAge(birthDate);
            if (age < 18) {
              errors.push("You must be 18 years or older to be eligible.");
            }
          }
          break;

        case "phone":
          fieldName = "Phone";
          const phoneWithoutDashes = value.replace(/-/g, "");
          if (!phoneWithoutDashes.match(/^\d{10}$/)) {
            errors.push(`${fieldName} must be a 10-digit number.`);
          }
          break;

        case "member_type":
          fieldName = "Member type";
          const memberTypeError = validateOrThrow(value, fieldName, "string");
          if (memberTypeError) {
            errors.push(memberTypeError);
          }
          break;

        case "gender":
          fieldName = "Gender";
          const genderError = validateOrThrow(value, fieldName, "string");
          if (genderError) {
            errors.push(genderError);
          }
          break;

        case "marital_status":
          fieldName = "Marital status";
          const maritalStatusError = validateOrThrow(value, fieldName, "string");
          if (maritalStatusError) {
            errors.push(maritalStatusError);
          }
          break;

        case "street_address":
          fieldName = "Street address";
          const streetAddresError = validateOrThrow(value, fieldName, "string");
          if (streetAddresError) {
            errors.push(streetAddresError);
          }
          break;

        case "city":
          fieldName = "City";
          const cityError = validateOrThrow(value, fieldName, "string");
          if (cityError) {
            errors.push(cityError);
          }
          break;

        case "state":
          fieldName = "State";
          if (!value || value.length !== 2 || !value.match(/^[A-Za-z]{2}$/)) {
            errors.push(`${fieldName} must be present and must be a 2-letter code.`);
          }
          break;

        case "zipcode":
          fieldName = "Zipcode";
          if (!value || !value.match(/^\d{5}$/)) {
            errors.push("Please enter a valid zipcode.");
          }
          break;

        case "reference_question":
          fieldName = "How did you hear about us?";
          const referenceQuestionError = validateOrThrow(value, fieldName, "string");
          if (referenceQuestionError) {
            errors.push(referenceQuestionError);
          }
          break;

        case "email":
          fieldName = "Email";
          const emailError = validateOrThrow(value, fieldName, "string");
          if (emailError) {
            errors.push(emailError);
          }
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(value)) {
            errors.push("Please enter a valid email address.");
          }
          break;

        case "apt_floor":
          if (value === null) {
            break;
          }
          break;

        default:
          fieldName = key;
          const fieldType = ["phone", "zipcode"].includes(key)
            ? "number"
            : "string";
          const error = validateOrThrow(value, fieldName, fieldType);
          if (error) {
            errors.push(error);
          }
          break;
      }
    }
  }

  return errors;
};

