import React, { useState } from 'react'
import './CourseModule.scss'
import CourseListItem from '../../../CourseSection/CourseListItem'
import { CourseItem } from '../../../../../interfaces/MemberPortal'
import DownArrowIcon from '../../../../../component/assests/icons/black-down-arrow.png'
import CheckedIcon from '../../../../../component/assests/icons/green-checked.png'
import LeftArrowIcon from '../../../../../component/assests/icons/left-arrow-black.png'

interface CourseModuleProps {
    list: CourseItem;
}

const CourseModule: React.FC<CourseModuleProps> = ({ list }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='course-module-component'>
            <div className='module-title-row' onClick={toggleExpand}>
                <span className='module-title-header'>{list.name}</span>
                {
                    list.isCompleted ? <img src={CheckedIcon} alt='icon' className='module-header-icon' /> : <>
                        {
                            <img src={!isExpanded ? DownArrowIcon : LeftArrowIcon} alt='icon' className='module-header-icon' />
                        }
                    </>
                }
            </div>
            {isExpanded && !list.isCompleted && (
                <div className='module-course-list'>
                    {list.details.map((item, index) => (
                        <CourseListItem
                            key={'item' + index}
                            image={item.image}
                            title={item.title}
                            duration={item.duration}
                            inProgress={item.inProgress}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default CourseModule