import { useNavigate } from 'react-router-dom';
import { WorkLifeSuccessPage } from '../../../../utils/constants';
import { useEffect, useRef } from 'react';
import { clearLocalStorageExceptKeys } from '../../../../utils/clearStorageData';
import './SuccessMessage.scss'

const SuccessMessage = () => {
  const navigate = useNavigate()
  const effectRef = useRef(false)
  
  useEffect(() => {
    if (effectRef.current === false)
      setTimeout(() => {
        clearLocalStorageExceptKeys()
      }, 5000)
    return () => { effectRef.current = true }
  }, [])

  return (
    <div className="success-message-page margin-content">
      <span className="subtitle">{WorkLifeSuccessPage.TITLE}</span>
      <span className="sub-title">{WorkLifeSuccessPage.SUBTITLE}</span>
      <span className="message-content">If you have an urgent need related to domestic violence, homelessness or a natural disaster, please call your EAP at: <span className='highlighted-text'>(# depends on client company)</span> to receive immediate assistance.</span>
      <button className='question-button-yes' onClick={() => navigate('/')}>{"Back to Individual Support"}</button>
    </div>
  );
};

export default SuccessMessage;
