import React, { useState, useRef, useContext } from "react";
import { Signup } from '../../utils/member-portal/constant'
import { common, signup, storageKeys } from '../../utils/constants'
import { validate, ValidationError } from './validateForm'
import { useNavigate } from "react-router-dom";
import './SignUp.scss'
import '../../styles/authFormsStyles.scss'
import { register } from '../../utils/ApiClient'
import Loader from "../../component/common/Loader/Loader";
import CredentialStorage from "../../utils/CredentialStorage";
import globalContext from "../../context/globalContext";

const SignUp = () => {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
    });
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorField, setErrorField] = useState<string | null>()
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const { globalErrorHandler }: any = useContext(globalContext)
    const organization_id = localStorage.getItem(storageKeys.ORGANIZATION_ID);
    const organization_name = localStorage.getItem(storageKeys.COMPANY);
    const navigate = useNavigate()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        const { name, value } = e.currentTarget;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const registerUser = async (): Promise<void> => {
        setLoading(true)
        try {
            const data = {
                first_name: formData.firstname,
                last_name: formData.lastname,
                email: formData.email,
                organization_id : organization_id,
                organization_name : organization_name
            }
            const response = await register(data)
            CredentialStorage.login(response);
            setLoading(false)
            navigate('/home')
        } catch (error) {
            setLoading(false)
            globalErrorHandler(error)
        }
    }

    const handleSubmit = (e: React.FormEvent, formData: { firstname: string; lastname: string; email: string }) => {
        e.preventDefault();
        const hasError: ValidationError | null = validate(formData)
        if (hasError) {
            {
                setError(true);
                setErrorMessage(hasError.message)
                if (hasError.field === "firstname") {
                    setErrorField("firstname")
                    firstnameRef.current?.focus();
                } else if (hasError.field === "lastname") {
                    setErrorField("lastname")
                    lastnameRef.current?.focus();
                } else if (hasError.field === "email") {
                    setErrorField("email")
                    emailRef.current?.focus();
                }
            }
        } else {
            setErrorField(null)
            registerUser()
        }
    };

    return (
        <div className='member-signup-page'>
            <>
                {
                    loading ? <Loader /> : <div className='member-signup-page'>
                        <span className="title">{Signup.title}</span>
                        <span className="subtitle">{Signup.subtitle}</span>
                        <form className="signup-form">
                            <div className="form-input">
                                <div className="input-div">
                                    <label>{common.FIRST_NAME}</label>
                                    <input
                                        type="text"
                                        name="firstname"
                                        className={`signup-page-input ${errorMessage && errorField === "firstname" ? "error-outline" : ""}`}
                                        placeholder={common.FIRST_NAME_PLACEHOLDER}
                                        onChange={(e) => handleInputChange(e)}
                                        ref={firstnameRef}
                                    />
                                </div>
                                <div className="input-div">
                                    <label>{common.LAST_NAME}</label>
                                    <input
                                        type="text"
                                        name="lastname"
                                        className={`signup-page-input ${errorMessage && errorField === "lastname" ? "error-outline" : ""}`}
                                        placeholder={common.LAST_NAME_PLACEHOLDER}
                                        onChange={(e) => handleInputChange(e)}
                                        ref={lastnameRef}
                                    />
                                </div>
                            </div>
                            <div className="input-div">
                                <label>{signup.EMAIL_ADDRESS}</label>
                                <input
                                    type="email"
                                    name="email"
                                    className={`signup-page-email-input ${errorMessage && errorField === "email" ? "error-outline" : ""}`}
                                    placeholder={signup.EMAIL_ADDRESS_PLACEHOLDER}
                                    onChange={(e) => handleInputChange(e)}
                                    ref={emailRef}
                                />
                            </div>
                            {error && <div className="error">*{errorMessage}*</div>}
                            <button
                                onClick={(e) => handleSubmit(e, formData)}
                                className="large-green-button">
                                {Signup.button}
                            </button>
                            <span className="nav-link nav-margin" onClick={() => navigate('/login')}>
                                {Signup.navlink}
                            </span>
                        </form></div>
                }
            </>

        </div>
    )
}

export default SignUp