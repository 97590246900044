import React from 'react'
import './ExcerciseInput.scss'

interface InputProps {
    title?: string;
}

const ExerciseInput: React.FC<InputProps> = ({ title }) => {
    return (
        <div className='exercise-input-component'>
            <span className='input-title'>{title}</span>
            <textarea className='exercise-input' rows={5} placeholder='Input here' />
        </div>
    )
}

export default ExerciseInput