import './MemberPortalHeader.scss'
import { useNavigate } from 'react-router-dom'
import React, { useContext } from 'react'
import GreenLogo from '../../assests/icons/logo-green.png'
import CloseIcon from '../../assests/icons/close-black.png'
import MenuIcon from '../../assests/icons/menu-black.png'
import globalContext from '../../../context/globalContext'
import ExpandableMenu from '../../../component/common/ExpandableMenu/ExpandableMenu'
import ResponsiveNavbar from '../../../component/layout/navbar/ResponsiveNavbar'
import NavBar from '../navbar/NavBar'

interface HeaderProps {
    handleHomeNavigation: () => void;
    currentPath: string;
}

const MemberPortalHeader: React.FC<HeaderProps> = ({ handleHomeNavigation, currentPath }) => {
    const { setOpenNavMenu, openNavMenu, isMenuExpanded }: any = useContext(globalContext)
    const clientLogo = currentPath === "/" || currentPath === "/sign-up" || currentPath === "/login"

    const navigate = useNavigate()

    const handleMenuClick = () => {
        setOpenNavMenu(!openNavMenu)
    }

    return (
        <header className='member-portal-header-component'>
            <div className='left-logo-section'>
                <img
                    src={GreenLogo}
                    alt="logo"
                    onClick={handleHomeNavigation}
                    className='green-logo'
                />
                {
                    !clientLogo && <span className='client-logo'>{"CLIENT LOGO"}</span>
                }
            </div>
            {
                currentPath === "/" || currentPath === "/sign-up" || currentPath === "/login" ? <div className="right-alinged-btns">
                    <span className="info-text">{"Learn More"}</span>
                    {
                        currentPath === "/login" ? <button className="sign-in-btn" onClick={() => navigate('/sign-up')}>{"SIGN UP"}</button> : <button className="sign-in-btn" onClick={() => navigate('/login')}>{"SIGN IN"}</button>
                    }
                </div> : <> {
                    <>
                        <img src={openNavMenu ? CloseIcon : MenuIcon}
                            alt='menu-icon'
                            className='menu-icon'
                            onClick={handleMenuClick} />
                        <div className='nav-bar-response'>
                            <NavBar />
                        </div>
                    </>
                }
                </>
            }
            {openNavMenu && <ResponsiveNavbar />}
            {isMenuExpanded && !openNavMenu && <ExpandableMenu />}
        </header>
    )
}

export default MemberPortalHeader