import React from 'react'
import './LongCradCommonstyles.scss'
import './ContentDescriptionCard.scss'
import WatchIcon from '../../assests/icons/clock.png'
import PersonIcon from '../../assests/icons/person-black.png'
import PlayIcon from '../../assests/icons/play.png'
import GreenProgressBar from '../Progressbar/GreenProgressBar'
import { useNavigate } from 'react-router-dom'

interface CardProps {
    title: string;
    button: string | boolean;
    content: string;
    drDetails: string;
    showProgressBar: boolean | number;
    resume?: boolean;
    exercise?: number;
    videos?: number;
    totalMins?: number
}

const ContentDescriptionCard: React.FC<CardProps> = ({ title, button, drDetails, resume, showProgressBar, content, exercise, videos, totalMins }) => {
    const navigate = useNavigate()
    return (
        <div className='content-desc-component right-content-details'>
            <div className='button-header'>
                <span className='button-header-title'>{title}</span>
                {
                    button ? <button className='green-border-button' onClick={() => navigate('/exercise')} >RESUME</button> : <button className='green-start-resume-btn' onClick={() => navigate('/module-videos')} >START</button>
                }
            </div>
            <span className='dr-details'>{drDetails}</span>
            {button &&
                <div className='progressbar-box'>
                    <GreenProgressBar remainingMins={showProgressBar} />
                    <span className='progress-text'>5 mins remaining</span>
                </div>
            }
            <span className='details'>{content}</span>
            <div className='button-bar'>
                <div className='button-box'>
                    <img src={WatchIcon} className='count-icon' />
                    <span className='counts-text'>{totalMins}mins</span>
                </div>
                <div className='button-box'>
                    <img src={PlayIcon} className='count-icon' />
                    <span className='counts-text'>{videos} videos</span>
                </div>
                <div className='button-box'>
                    <img src={PersonIcon} className='count-icon' />
                    <span className='counts-text'>{exercise} exercises</span>
                </div>
            </div>
        </div>
    )
}

export default ContentDescriptionCard