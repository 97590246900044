import React from 'react'
import './TopicCard.scss'
import './LongCradCommonstyles.scss'
import RightArrow from '../../assests/icons/right-side-arrow.png'

interface TopicProps {
    title: string;
    image: string;
    menuItems: { title: string, src: string }[];
}

const TopicCard: React.FC<TopicProps> = ({ title, image, menuItems }) => {
    return (
        <div className='topic-card-component'>
            <img className='left-side-img' src={image} />
            <div className='right-content-details'>
                <div className='details-header'>
                    <span className='details-header-title'>{title}</span>
                    <span className='learn-link'>{"Learn more"}</span> 
                </div>
                <div className='detail-item'></div>
                {
                    menuItems.map((item, index) => {
                        return <div className='detail-item' key={index + "item"}>
                            <div className='icon-box'>
                                <img src={item.src} alt='icon' />
                                <span className='title-line'>{item.title}</span>
                            </div>
                            <img src={RightArrow} alt='arrow' className='arrow-icon' />
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default TopicCard