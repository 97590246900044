import React, { useState, useEffect } from 'react'
import './JournalEntry.scss'
import EditIcon from '../../assests/icons/edit-icon.png'
import BinIcon from '../../assests/icons/green-bin.png'
import CancelIcon from '../../assests/icons/cancel.png'
import { JournalEntryQuestions } from '../../../interfaces/MemberPortal'
import ExerciseInput from '../Input/ExerciseInput'
import IncrementDecrementButton from '../Counter/IncrementDecrementButton'
import DateAndTimeInput from '../Input/DateAndTimeInput'
import SmallGreenButton from '../Button/SmallGreenButton'

interface JournalEntryProps {
    entries: JournalEntryQuestions[];
    handleDeleteEntry: (index: number) => void;
}

const JournalEntry: React.FC<JournalEntryProps> = ({ entries, handleDeleteEntry }) => {
    const [entriesData, setEntriesData] = useState(entries);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editedEntry, setEditedEntry] = useState<JournalEntryQuestions | null>(null);

    useEffect(() => {
        setEntriesData(entries);
    }, [entries]);

    const handleEditClick = (index: number | null) => {
        if (index === null) {
            setEditingIndex(null);
            setEditedEntry(null);
        } else {
            setEditingIndex(index);
            setEditedEntry({ ...entriesData[index] });
        }
    };

    const handleInputChange = (field: keyof JournalEntryQuestions, value: any) => {
        if (editedEntry) {
            setEditedEntry({
                ...editedEntry,
                [field]: value,
            });
        }
    };

    const handleSaveClick = () => {
        if (editingIndex !== null && editedEntry) {
            const updatedEntries = [...entriesData];
            updatedEntries[editingIndex] = editedEntry;
            setEntriesData(updatedEntries);
            setEditingIndex(null);
            setEditedEntry(null);
        }
    };

    const isEntryNew = (entry: JournalEntryQuestions) => {
        return entry.journalEntry.date !== "" &&
            entry.journalEntry.time !== "" &&
            entry.situation !== "" &&
            entry.thoughts !== "" &&
            entry.depression !== 0 &&
            entry.result !== "";
    };

    return (
        <>
            {entriesData.map((ques, index) => (
                <div className='journal-entry-component' key={index}>
                    <div className='content-section'>
                        <div className='ques-ans'>
                            <span className='input-title'>Journal Entry</span>
                            <div className='answers opacity'>
                                {editingIndex !== index && isEntryNew(ques) ? (
                                    <>
                                        <span>{ques.journalEntry.date}</span>
                                        <span>{ques.journalEntry.time}</span>
                                    </>
                                    // <>
                                    //     <input
                                    //         type="text"
                                    //         value={editedEntry?.journalEntry.date}
                                    //         onChange={(e) => handleInputChange("journalEntry", { ...editedEntry?.journalEntry, date: e.target.value })}
                                    //     />
                                    //     <input
                                    //         type="text"
                                    //         value={editedEntry?.journalEntry.time}
                                    //         onChange={(e) => handleInputChange("journalEntry", { ...editedEntry?.journalEntry, time: e.target.value })}
                                    //     />
                                    // </>
                                ) : (
                                    <DateAndTimeInput hideButton={true} />
                                )}
                            </div>
                        </div>
                        <div className='icons-section'>
                            {isEntryNew(ques) && (
                                editingIndex === index ? (
                                    <img src={CancelIcon} alt='cancel' className='icon-img' onClick={() => handleEditClick(null)} />
                                ) : (
                                    <img src={EditIcon} alt='edit' className='icon-img' onClick={() => handleEditClick(index)} />
                                )
                            )}
                            <img src={BinIcon} alt='delete' className='icon-img' onClick={() => handleDeleteEntry(index)} />
                        </div></div>
                    <div className='journal-question'>
                        <div className='ques-ans'>
                            <span className='input-title'>Situation/Trigger:</span>
                            {editingIndex !== index && isEntryNew(ques) ? (
                                <span className='answers'>{ques.situation}</span>
                                // <input
                                //     type="text"
                                //     value={editedEntry?.situation}
                                //     onChange={(e) => handleInputChange("situation", e.target.value)}
                                // />
                            ) : (
                                <ExerciseInput />
                            )}
                        </div>
                        <div className='ques-ans'>
                            <span className='input-title'>Thoughts:</span>
                            {editingIndex !== index && isEntryNew(ques) ? (
                                <span className='answers'>{ques.thoughts}</span>
                                // <input
                                //     type="text"
                                //     value={editedEntry?.thoughts}
                                //     onChange={(e) => handleInputChange("thoughts", e.target.value)}
                                // />
                            ) : (
                                <ExerciseInput />
                            )}
                        </div>
                        <div className='depression-severity'>
                            <span className='input-title'>Depression Severity (1-10):</span>
                            {editingIndex !== index && isEntryNew(ques) ? (
                                <span className='answers'>{ques.depression}</span>
                                // <input
                                //     type="number"
                                //     value={editedEntry?.depression}
                                //     onChange={(e) => handleInputChange("depression", Number(e.target.value))}
                                // />
                            ) : (
                                <IncrementDecrementButton />
                            )}
                        </div>
                        <div className='ques-ans'>
                            <span className='input-title'>Result/Action/Behavior (What did you do? Feel? Experience?)</span>
                            {editingIndex !== index && isEntryNew(ques) ? (
                                <span className='answers'>{ques.result}</span>
                                // <input
                                //     type="text"
                                //     value={editedEntry?.result}
                                //     onChange={(e) => handleInputChange("result", e.target.value)}
                                // />
                            ) : (
                                <ExerciseInput />
                            )}
                        </div>
                    </div>
                    {
                        editingIndex === index && <SmallGreenButton name={"Save"} onClick={handleSaveClick} />
                    }
                </div>
            ))}
        </>
    )
}

export default React.memo(JournalEntry)