import './IndividualSupport.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useRef, ElementRef  } from "react";
import PreviousIcon from '../component/assests/icons/previous.png'
import NextIcon from '../component/assests/icons/next.png'
import TextCoachImg from '../component/assests/images/text-coach.png'
import SelfScheduleImg from '../component/assests/images/short-term.png'
import WorkLifeImg from '../component/assests/images/worklife.png'
import GetAnsImg from '../component/assests/images/get-ans.png'
import SupportCard from '../component/common/Cards/SupportCard'

interface SliderRef {
    slickNext: () => void;
    slickPrev: () => void;
}

const IndividualSupport: React.FC = () => {
    const sliderRef = useRef<SliderRef | null>(null);
    const Cards = [
        {
            title: "Text Coaching",
            image: TextCoachImg,
            description: "Chat with a personal coach and learn new strategies for dealing with stress, personal relationships, and tough transitions.",
            button: "Connect with a Coach"
        },
        {
            title: "Short-Term Counseling",
            image: SelfScheduleImg,
            description: "Get hands-on help through life’s biggest challenges such as the loss of a loved one, anxiety, loneliness or significant distress.",
            button: "Schedule First Session"
        },
        {
            title: "Work-Life Request",
            image: WorkLifeImg,
            description: "Request referrals for services in your local area for childcare, elder care, daily living, legal and financial needs.",
            button: "Get a Referral"
        },
        {
            title: "Get Answers",
            image: GetAnsImg,
            description: "Chat with a benefits specialist to learn more about the different benefits available to you.",
            button: "Get a Referral"
        },
    ]
    const settings = {
        variableWidth: true,
        dots: false,
        nav: false,
        infinite: true,
        speed: 100,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],

    };

    const goToNext = () => {
        sliderRef.current?.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current?.slickPrev();
    };

    return (
        <div className='individual-support-component'>
            <div className='support-header'>
                <span className='support-title'>Individual support</span>
                <div className='slider-buttons'>
                    <img src={PreviousIcon} alt='previous' className='slider-button-icon' onClick={goToPrev} />
                    <img src={NextIcon} alt='next' className='slider-button-icon' onClick={goToNext} />
                </div>
            </div>
            <div className='slider-container'>
                <Slider {...settings} ref={sliderRef}>
                    {
                        Cards.map((item, index) => {
                            return <SupportCard
                                key={index + "card"}
                                title={item.title}
                                image={item.image}
                                description={item.description}
                                button={item.button} />
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default IndividualSupport