import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const WorkLifePageBlock = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const category = localStorage.getItem(storageKeys.CATEGORY);
    if (!category) {
      navigate("/worklife-form");
    }
  }, []);
  return (
    <div></div>
  )
}

export default WorkLifePageBlock