import React, { useState } from "react";
import { Line } from "rc-progress";
import "./ProgressBar.scss";

interface ProgressProps {
  remainingMins?: number | boolean
}

const GreenProgressBar: React.FC<ProgressProps> = ({ remainingMins }) => {
  const [percentage, setPercentage] = useState<number>(50);

  return (
    <Line
      percent={percentage}
      strokeWidth={1}
      strokeColor="#2B7272"
      trailColor="#E5E5E5"
      className="progressbar-component"
    />
  );
};

export default GreenProgressBar;
