import PersonIcon from '../../component/assests/icons/person-green.png'
import PlayIcon from '../../component/assests/icons/play-green.png'
import BookIcon from '../../component/assests/icons/book-green.png'
import { CourseItem } from '../../interfaces/MemberPortal'

export const CBTCourseList: CourseItem[] = [
    {
        name: "The beginning",
        details: [
            { title: "The Lemon", duration: "2 minutes", image: PlayIcon, inProgress: true },
            { title: "How do you see the world? Perception and interpretation", duration: "1 minutes", image: PlayIcon, inProgress: false },
            { title: "The Cognitive Triangle - Your thoughts, behaviors and emotions ", duration: "2 minutes", image: BookIcon, inProgress: false },
        ],
        path: "/exercise",
        isCompleted: false
    },
    {
        name: "Your “self talk”",
        path: "/exercise",
        details: [
            { title: "What’s your “Self Talk” / “Inner Dialogue”? How do you see the world Part 2", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "Connecting thoughts, behaviors and emotions - How they are all connected", duration: "1 Minute", image: PlayIcon, inProgress: false },
            { title: "Putting your “self talk” on the triangle", duration: "2 Minute", image: BookIcon, inProgress: false },
            { title: "OPTIONAL - Introducing the “ABC Method”", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "How your “self talk” affects you", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "Does your “self talk” serve you?", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "Challenging your “self talk” / “talking back”", duration: "2 Minute", image: BookIcon, inProgress: false },
        ],
        isCompleted: false
    },
    {
        name: "Self reflection",
        path: "/exercise",
        details: [
            { title: "Cultivating inner kindness to adjust your “self talk”", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "Cultivating Mindfulness or “Defusion” to adjust your “self talk”", duration: "1 Minute", image: PlayIcon, inProgress: false },
            { title: "Entry on “core beliefs” - where does our “self talk” comes from. What are your “core beliefs”", duration: "2 Minute", image: BookIcon, inProgress: false },
            { title: "Reflecting on your self talk", duration: "2 Minute", image: PersonIcon, inProgress: false },
            { title: "Challenge your self talk", duration: "2 Minute", image: PersonIcon, inProgress: false },
        ],
        isCompleted: false
    },

]