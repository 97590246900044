import './UserProfileMenu.scss'
import { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { logout } from "../../../utils/ApiClient";
import globalContext from "../../../context/globalContext";
import { clearLocalStorageExceptOID } from "../../../utils/clearStorageData";
import { storageKeys } from "../../../utils/constants";

const UserProfileMenu = () => {
    const { globalErrorHandler }: any = useContext(globalContext);
    const navigate = useNavigate();
    
    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
            navigate("/login");
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <div className='user-profile-menu-component'>
            <span className='menu-options'>{"Your Profile"}</span>
            <span className='menu-options'>{"Account Settings"}</span>
            <span className='menu-options' onClick={handleLogout} >{"Sign Out"}</span>
        </div>
    )
}

export default UserProfileMenu