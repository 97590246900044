import { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import globalContext from '../../../context/globalContext'
import { NavBarLinks } from '../../../utils/member-portal/constant'
import ExpandIcon from '../../assests/icons/expand-menu.png'
import DropdownIcon from '../../assests/icons/expand-icon.png'
import UserAvatar from '../../assests/icons/user-avatar-green.png'
import UserProfileMenu from './UserProfileMenu'
import './ResponsiveNavbar.scss'
import { ApiCareSquadCategory } from '../../../interfaces/MemberPortal'
import { capitalizeFirstLetter } from '../../../utils/helperFunctions'

const ResponsiveNavbar = () => {
  const [openExpolreTopic, setOpenExpolreTopic] = useState<boolean>(false)
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
  const { setOpenProfileMenu, openProfileMenu, categories }: any = useContext(globalContext)

  const handleMenuClick = (index: number) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="response-navbar-component">
      <div onClick={() => setOpenExpolreTopic(!openExpolreTopic)} className='menu-link-buttons'>
        <span>{NavBarLinks.EXPLORE_TOPIC.name}</span>
        <img src={openExpolreTopic ? DropdownIcon : ExpandIcon} alt='expand' />
      </div>
      {
        openExpolreTopic && <> {
          categories.map((item: ApiCareSquadCategory, index: number) => {
            return (
              <div key={"category" + index}>
                <div key={"menu" + index} className='menu-link-buttons  link-menu-items' onClick={() => handleMenuClick(index)}>
                  <span>{capitalizeFirstLetter(item.label)}</span>
                  <img src={openMenuIndex === index ? DropdownIcon : ExpandIcon} alt='expand' />
                </div>
                {openMenuIndex === index && item.meta_data && (
                  <div className="submenu">
                    {item.meta_data.map((subItem, subIndex) => (
                      <div key={"submenu" + subIndex} className="submenu-item">
                        {capitalizeFirstLetter(subItem.name)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          })
        } </>
      }
      <Link to="/individual-support" className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.INDIVIDUAL_SUPPORT.name}</div></Link>
      <Link to="/faqs" className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.FAQS.name}</div></Link>
      <Link to="/contact-us" className="no-underline"><div className='menu-link-buttons'>{NavBarLinks.CONTACTUS.name}</div></Link>
      <div className='menu-link-buttons' onClick={() => setOpenProfileMenu(!openProfileMenu)}>
        <div className='user-section-response'>
          <img src={UserAvatar} alt='user-avatar' />
          <span className='user-name'>{"John Levy"}</span>
        </div>
        <img src={openProfileMenu ? DropdownIcon : ExpandIcon} alt='expand' />
      </div>
      {
        openProfileMenu && <UserProfileMenu />
      }
    </div>
  )
}

export default ResponsiveNavbar