import React from 'react'
import './SupportCard.scss'

interface CardProps {
    title: string;
    image: string;
    description: string;
    button: string
}

const SupportCard: React.FC<CardProps> = ({ title, image, description, button }) => {
    return (
        <div className='support-card-component'>
            <img src={image} className='support-card-img' alt='image' />
            <div className='support-card-content'>
                <span className='support-title'>{title}</span>
                <span className='support-description'>{description}</span>
                <button className='support-button'>{button}</button>
            </div>
        </div>
    )
}

export default SupportCard