import { useNavigate, NavigateFunction } from "react-router-dom";
import { storageKeys, common } from "./constants";
import { clearLocalStorageExceptKeys } from "./clearStorageData";

export const useNavigationHandler = (): {
  navigateWithOption: (button: string, selectedOption?: string) => void;
  flowNavigation: (flow: string) => void;
  changedFlowNavigation: (flow: string) => void;
} => {
  const navigate: NavigateFunction = useNavigate();

  const navigateWithOption = (button: string, selectedOption?: string) => {
    if (selectedOption && button === common.NEXT) {
      localStorage.setItem(storageKeys.TEXT_COACHNG_QUESTION, selectedOption);
    }
    const token = localStorage.getItem(storageKeys.TOKEN);
    const flow =
      button === common.NEXT && selectedOption
        ? common.TEXT_COACHING
        : common.SELF_SCHEDULING;
    localStorage.setItem(storageKeys.FLOW, flow);
    const queryParams: { flow: string } = {
      flow: flow,
    };
    const searchParams = new URLSearchParams(queryParams).toString();
    if (token) {
      navigate("/age-gate");
    } else {
      navigate({
        pathname: "/register",
        search: `?${searchParams}`,
      });
    }
  };

  const flowNavigation = (flow: string) => {
    const flowName = flow === common.GET_ANSWERS ? common.TEXT_COACHING : flow;
    localStorage.setItem(storageKeys.FLOW, flowName);
    const queryParams: { flow: string } = {
      flow: flowName,
    };
    const searchParams = new URLSearchParams(queryParams).toString();
    if (flow === common.TEXT_COACHING) {
      navigate("/support-request");
    } else if (flow === common.GET_ANSWERS) {
      localStorage.setItem(
        storageKeys.TEXT_COACHNG_QUESTION,
        common.TEXT_COACHING_OPTION
      );
      navigate({
        pathname: "/register",
        search: `?${searchParams}`,
      });
    } else {
      navigate({
        pathname: "/register",
        search: `?${searchParams}`,
      });
    }
  };

  const changedFlowNavigation = (flow: string) => {
    let additionalKey = localStorage.getItem(storageKeys.TEXT_COACHNG_QUESTION);
    if (flow === common.TEXT_COACHING) {
      clearLocalStorageExceptKeys(additionalKey);
    } else clearLocalStorageExceptKeys();
    const flowName = flow === common.GET_ANSWERS ? common.TEXT_COACHING : flow;
    localStorage.setItem(storageKeys.FLOW, flowName);
    if (flow === common.TEXT_COACHING) {
      const optionString = localStorage.getItem(
        storageKeys.TEXT_COACHNG_QUESTION
      );
      if (optionString) {
        navigate("/age-gate");
      } else navigate("/support-request");
    } else if (flow === common.WORKLIFE_REQUEST) {
      navigate("/worklife-form");
    } else {
      if (flow === common.GET_ANSWERS) {
        localStorage.setItem(
          storageKeys.TEXT_COACHNG_QUESTION,
          common.TEXT_COACHING_OPTION
        );
      }
      navigate("/age-gate");
    }
  };

  return { navigateWithOption, flowNavigation, changedFlowNavigation };
};
