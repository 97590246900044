import { useContext } from 'react'
import './MemberHome.scss'

import globalContext from '../../context/globalContext'

import HeroSectionHome from '../../sections/HeroSectionHome'
import TopicGrid from '../../sections/HeroGridImages'
import PopularTopics from '../../sections/PopularTopics'
import IndividualSupport from '../../sections/IndividualSupport'

const MemberPortalHome = () => {
  const { isMenuExpanded, openNavMenu }: any = useContext(globalContext)

  return (
    <div className='member-home-page'>
      <div className='container member-home-page'>
        <HeroSectionHome />
        <TopicGrid />
        <PopularTopics />
      </div>
      <IndividualSupport />
    </div>
  )
}

export default MemberPortalHome