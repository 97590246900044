import { FC, useState } from "react";
import GlobalContext from "./globalContext";
import { clearLocalStorageExceptOID } from "../utils/clearStorageData";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { storageKeys } from "../utils/constants";

type GlobalStateProviderProps = {
  children: React.ReactNode;
};

const GlobalState: FC<GlobalStateProviderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [globalError, setGlobalError] = useState<string>("");
  const [serviceType, setServiceType] = useState<string[] | null>(null);
  const [loader, setLoader] = useState(true);
  const [menu, setMenu] = useState<boolean>(false);
  const [openNavMenu, setOpenNavMenu] = useState<boolean>(false)
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
  const [selectedNavMenu, setSelectedNavMenu] = useState<string | null>()
  const [categories, setCategories] = useState([]);
  
  const globalErrorHandler = (error: any) => {
    if (error.response?.status === 401) {
      clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      navigate(`/login?redirect_url=${pathName}`);
    } else if (error.response?.status === 400) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else if (error.response?.status === 404) {
      setGlobalError(error.response?.data.message);
      Sentry.captureMessage(error.response?.data.message);
    } else {
      setGlobalError(error.message);
      Sentry.captureException(error);
    }
  };

  const globalState: any = {
    globalError,
    serviceType,
    loader,
    menu,
    openNavMenu,
    isMenuExpanded,
    openProfileMenu,
    categories,
    setCategories,
    selectedNavMenu,
    setServiceType,
    setGlobalError,
    globalErrorHandler,
    setLoader,
    setMenu,
    setOpenNavMenu,
    setIsMenuExpanded,
    setOpenProfileMenu,
    setSelectedNavMenu,
  };

  return (
    <GlobalContext.Provider value={globalState}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
