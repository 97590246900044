import ContentDescriptionCard from '../../component/common/Cards/ContentDescriptionCard'
import './MentalHealthCourse.scss'
import CourseSection from '../../component/common/CourseSection/CourseSection'
import { CBTCourseList } from '../../utils/member-portal/CBTCourseList'

const MentalHealthCourse = () => {
  const content = "Introduction to Cognitive Behavioral Therapy (CBT) is a foundational course that covers the basics of CBT, focusing on the connection between thoughts, emotions, and behaviors. It prepares participants for future modules by introducing key techniques for recognizing and challenging negative thought patterns."
  const drDetails = "Curated by Dr.Andrew Schmidt"
  return (
    <div className='mental-health-course-page'>
      <ContentDescriptionCard
        title='Intro to CBT'
        content={content}
        resume={false}
        showProgressBar={true}
        button='Resume'
        drDetails={drDetails} />
      <div className='container'>
        <CourseSection CBTCourseList={CBTCourseList} />
      </div>
    </div>
  )
}

export default MentalHealthCourse