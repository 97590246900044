import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import AppRouter from "./routes/AppRouter";
import { exploreTopicsCategories } from "./utils/ApiClient";
import globalContext from "./context/globalContext"

const App: React.FC = () => {
  const { setCategories, globalErrorHandler }: any = useContext(globalContext)
  const location = useLocation();
  
  const fetchCategories = async (): Promise<void> => {
    try {
      const response = await exploreTopicsCategories();
      setCategories(response.data.data)
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (location.pathname === '/exercise') {
      document.body.classList.add('route-active');
      return () => {
        document.body.classList.remove('route-active');
      };
    }
  }, [location]);

  return (
    <AppRouter />
  );
};

export default App;
