import React from 'react'
import './DropDownMenu.scss'

interface DropdownProps {
    question: string
    // options: string[]
}

const DropDownMenu: React.FC<DropdownProps> = ({ question }) => {
    return (
        <div className='dropdown-menu-component'>
            <span className='input-title'>{question}</span>
            <select className='dropdown-menu'>
                <option defaultValue='' disabled selected>Choose an option</option>
                <option value="apple">Option 1</option>
                <option value="banana">Option 2</option>
                <option value="cherry">Option 3</option>
                <option value="grape">Option 4</option>
            </select>
        </div>
    )
}

export default DropDownMenu