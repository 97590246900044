import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import Excercise from "./Exercise";

const Index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<Excercise />} />
  );
};

export default Index;
