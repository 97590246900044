import './DigitalContentCard.scss'
import './SupportCard.scss'
import React from 'react';

interface CardProps {
    id: number
    title: string;
    image: string;
}

const DigitalContentCard: React.FC<CardProps> = ({ title, image, id }) => {
    return (
        <div className='digital-content-card-component'>
            <div className='support-card-component'>
                <img className='support-card-img' src={image} alt='digital' />
                <div className='support-card-content'>
                    <span className='digitalcard-name'>{title}</span>
                    <span className="card-link">Read more</span>
                </div>
            </div>
        </div>
    )
}

export default DigitalContentCard