import './Exercise.scss'
import ExerciseModule from '../../component/common/ModuleInterface/ExerciseModule'
import ModuleInterface from '../../component/common/ModuleInterface/ModuleInterface'

const Excercise = () => {
    return (
        <div className='exercise-page'>
            <ModuleInterface content={
                <ExerciseModule />} />
        </div>
    )
}

export default Excercise