import React, { useState } from 'react';
import './ScheduleTable.scss';
import HtmlTooltip from '@mui/material/Tooltip';

type Day = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";

interface TimeSlot {
  time: string;
  activities: Record<Day, string>;
}

const ScheduleTable: React.FC = () => {
  const days: Day[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const timeSlots: TimeSlot[] = [
    { time: "6AM to 8AM", activities: { Monday: "Woke up and had breakfast", Tuesday: "Team meeting", Wednesday: "Team meeting", Thursday: "Team meeting", Friday: "Team meeting", Saturday: "Team meeting", Sunday: "Team meeting" } },
    { time: "8AM to 10AM", activities: { Monday: "Got ready and went to work", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "" } },
    { time: "10AM to 12PM", activities: { Monday: "Team meeting", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "" } },
    { time: "12PM to 2PM", activities: { Monday: "Woke up and had breakfast", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "Woke up and had breakfast" } },
    { time: "2PM to 4PM", activities: { Monday: "", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "" } },
    { time: "4PM to 6PM", activities: { Monday: "", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "Woke up and had breakfast", Sunday: "" } },
    { time: "6PM to 8PM", activities: { Monday: "Woke up and had breakfast", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "" } },
    { time: "8PM to 10PM", activities: { Monday: "", Tuesday: "", Wednesday: "", Thursday: "Woke up and had breakfast", Friday: "", Saturday: "", Sunday: "" } },
    { time: "10PM to 12PM", activities: { Monday: "", Tuesday: "", Wednesday: "", Thursday: "", Friday: "", Saturday: "", Sunday: "" } },
  ];
  const [weekday, setWeekDay] = useState<string | null>("")
  const [timeSlot, setTimeSlot] = useState<string | null>("")
  const [content, setContent] = useState<string | null>("")

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const day = e.currentTarget.getAttribute('data-day');
    const timeSlot = e.currentTarget.getAttribute('data-time');
    const data = e.currentTarget.textContent;
    setWeekDay(day)
    setTimeSlot(timeSlot)
    setContent(data)
  }
  return (
    <div className='schedule-table-component'>
      <span className='input-title'>Your current week</span>
      <table>
        <thead className='table-head'>
          <tr>
            <th className='day-title'></th>
            {days.map(day => (
              <th key={day} className='day-header'>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body'>
          {timeSlots.map((slot, index) => (
            <tr key={index}>
              <td className="time-slot-header">{slot.time}</td>
              {days.map(day => {
                return (
                  <HtmlTooltip key={day} placement='right-end' enterDelay={100} title={
                    <div className='cell-tooltip'>
                      <span className='day-info'>{weekday}; {timeSlot}</span>
                      <span className='tooltip-content'>Activity : </span>
                      <span className='tooltip-content'>{content}</span>
                      <span className='tooltip-content'>Mood Rating : </span>
                    </div>
                  } >
                    <td key={day} className="time-slot" data-day={day} data-time={slot.time} onMouseEnter={(e) => handleHover(e)}>
                      {slot.activities[day] || <span>&nbsp;</span>}
                    </td> 
                  </HtmlTooltip>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div >
  );
};

export default ScheduleTable;
