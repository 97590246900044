import './Module.scss'
import PlayIcon from '../../component/assests/icons/play.png'
import SlackIcon from '../../component/assests/icons/slack.png'
import BookIcon from '../../component/assests/icons/book.png'
import PeopleIcon from '../../component/assests/icons/two-people.png'
import TextCoachImg from '../../component/assests/images/text-coach.png'
import SelfScheduleImg from '../../component/assests/images/short-term.png'
import GetAnsImg from '../../component/assests/images/get-ans.png'
import MentalHealthActionCard from '../../component/common/Cards/MentalHealthActionCard'
import DigitalContentCard from '../../component/common/Cards/DigitalContentCard'
import SupportCard from '../../component/common/Cards/SupportCard'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import { getModuleDetails, getModuleDigitalContent } from '../../utils/ApiClient'
import globalContext from '../../context/globalContext'
import { ApiArticle, ApiCBTModule } from '../../interfaces/MemberPortal'
import Loader from '../../component/common/Loader/Loader'

const MentalHealth = () => {
    const [modules, setModules] = useState<ApiCBTModule[]>([])
    const [details, setDetails] = useState<ApiCBTModule>({} as ApiCBTModule);
    const [digitalContent, setDigitalContent] = useState<ApiArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const { id } = useParams<{ id: string }>();
    const moduleId = id ? parseInt(id, 10) : null;
    const { globalErrorHandler }: any = useContext(globalContext)
    const fetchModuleData = async (): Promise<void> => {
        try {
            const [moduleDetailsResponse, digitalContentResponse] = await Promise.all([
                getModuleDetails(moduleId),
                getModuleDigitalContent(moduleId),
            ]);
            setModules(moduleDetailsResponse.data.data.cbt_modules);
            setDetails(moduleDetailsResponse.data.data);
            setDigitalContent(digitalContentResponse.data.data.digital_content);
            setLoading(false)
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    useEffect(() => {
        fetchModuleData();
    }, []);

    const Cards = [
        {
            title: "Text Coaching",
            image: TextCoachImg,
            description: "Chat with a personal coach and learn new strategies for dealing with stress, personal relationships, and tough transitions.",
            button: "Connect with a Coach"
        },
        {
            title: "Short-Term Counseling",
            image: SelfScheduleImg,
            description: "Get hands-on help through life’s biggest challenges such as the loss of a loved one, anxiety, loneliness or significant distress.",
            button: "Schedule First Session"
        },
        {
            title: "Get Answers",
            image: GetAnsImg,
            description: "Chat with a benefits specialist to learn more about the different benefits available to you.",
            button: "Get a Referral"
        },
    ]

    return (
        <>{
            loading ? <Loader /> : <div className='mental-health-page'>
                <img src={details.thumbnail_url} alt='cover-img' className='cover-image' />
                <div className='container'>
                    <div className='page-header-section'>
                        <span className='page-title'>{details.name}</span>
                        <div className='right-section'>
                            <span className='health-page-content'>{details.description}</span>
                            <div className='action-buttons-grid' >
                                <div className='action-buttons'>
                                    <img src={PlayIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.digital_content_count}Modules</span>
                                </div>
                                <div className='action-buttons'>
                                    <img src={SlackIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.video_count} Videos</span>
                                </div>
                                <div className='action-buttons'>
                                    <img src={BookIcon} alt='play-icon' className='mini-icons' />
                                    <span>{details.exercise_count} Articles</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cards-container'>
                        {
                            modules.map((item: ApiCBTModule, index) => {
                                return <Link to={'/mental-health-course'} key={index + "act_card"} className='no-underline'>
                                    <MentalHealthActionCard
                                        title={item.name}
                                        exercise={item.total_exercises}
                                        videos={item.total_videos}
                                        totalMins={item.total_minutes}
                                        button={item.is_started}
                                        drDetails={item.curator_name}
                                        image={item.thumbnail}
                                        content={item.description}
                                        showProgressBar={item.remaining_mintues} />
                                </Link>
                            })
                        }
                    </div>
                    <div className='sub-header'>
                        <img src={BookIcon} alt='digital-book' className='sub-header-icon' />
                        <span className='sub-header-text'>Digital Content</span>
                    </div>
                    <div className='digital-card-grid'>
                        {
                            digitalContent.map((item, index) => {
                                return <DigitalContentCard
                                    key={"card" + index}
                                    id={item.article_id}
                                    title={item.title}
                                    image={item.banner}
                                />
                            })
                        }
                    </div>
                    <div className='sub-header'>
                        <img src={PeopleIcon} alt='digital-book' className='sub-header-icon' />
                        <span className='sub-header-text'>Resources</span>
                    </div>
                    <div className='digital-card-grid'>
                        {
                            Cards.map((item, index) => {
                                return <SupportCard
                                    key={index + "card"}
                                    title={item.title}
                                    image={item.image}
                                    description={item.description}
                                    button={item.button} />
                            })
                        }
                    </div>
                </div>
            </div>
        }</>
    )
}

export default MentalHealth