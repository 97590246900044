import React, { useState } from 'react'
import './DateAndTimeInput.scss'
import BinIcon from '../../assests/icons/green-bin.png'
import { TimePicker } from '@mui/x-date-pickers';
import { CCADatePicker } from '../CCADatePicker/CCADatePicker'


interface Props {
    title?: string;
    hideButton: boolean;
}

const DateAndTimeInput: React.FC<Props> = ({ title, hideButton }) => {
    const [date, setDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<Date | null>(null);
    const handleDateChange = (date: Date | null) => {
        setDate(date);
    };

    return (
        <div className='date-and-time-component'>
            <div className='title-btn-bar'>
                <span className='input-title'>{title}</span>
                {
                    !hideButton && <img src={BinIcon} alt='bin-icon' className='green-bin-icon' />
                }
            </div>
            <div className='input-grid'>
                <CCADatePicker
                    placeholder='Date'
                    value={date}
                    onChange={(date: Date | null) => handleDateChange(date)} />
                <TimePicker
                    label="Time"
                    value={selectedTime}
                    onChange={(newValue) => setSelectedTime(newValue)}
                />
            </div>
        </div>
    )
}

export default DateAndTimeInput