import './HeroSection.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HeroSectionHome = () => {
    const settings = {
        dots: true,
        nav: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
    };
    return (
        <div className='hero-section-component'>
            <Slider {...settings}>
                <div className='hero-img-one'>
                    <div className="hero-slider-conatiner">
                        <div className='hero-image'>
                            <h2 className='hero-title'>{"September is National Recovery Month"}</h2>
                            <h4 className='hero-content show-content'>{"Celebrating the nation’s strong recovery community as well as the dedicated programs and providers who make recovery possible."}</h4>
                            <div className='button-box' ><button className='hero-button'>{'READ MORE'}</button></div>
                        </div>
                    </div>
                </div>
                <div className='hero-img-two'>
                    <div className='center-content'>
                        <h3 className='hero-title'>{"If you are in need of immediate help please call our 24/7/365 access line."}</h3>
                        <h2 className='decorated-text'>{"800-833-8707"}</h2>
                    </div>
                </div>
                <div className='hero-img-three'>
                    <div className="hero-slider-conatiner">
                        <div className='hero-image'>
                            <h2 className='hero-title'>{"Headlines that are  important to you"}</h2>
                            <div className='button-box' ><button className='hero-button'>{'READ MORE'}</button></div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default HeroSectionHome