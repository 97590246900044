import React from 'react'
import './QuestionnaireQues.scss'

interface Props {
    title: string;
    description: string
}

const QuestionnarieTitleDesc: React.FC<Props> = ({ title, description }) => {
    return (
        <div className='questionnaire-ques-component'>
            <span className='module-common-title'>{title}</span>
            <p className='questionnarie-content'>{description}</p>
        </div>
    )
}

export default QuestionnarieTitleDesc