import './FAQHeader.scss'

const FAQHeader = () => {
    return (
        <div className='faq-header-component'>
            <span className='title'>Frequently asked questions</span>
            <span className='faq-header-subtitle'>These are the most commonly asked questions about CCA.
                Cant find what you’re looking for? Feel free to reach out to us!</span>
        </div>
    )
}

export default FAQHeader