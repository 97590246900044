import React, { useContext, useState, useRef, useEffect } from 'react';
import { getFormatDateString, extractTimeFromString } from '../../../../utils/helperFunctions';
import { getEAPCaseFileData, getEAPCases, searchEapxCase, EAPXCaseList, searchCasePRTManager } from '../../../../utils/ApiClient';
import globalContext from '../../../../context/globalContext';
import SearchIconGreen from '../../../assests/icons/search-icon-green.png';
import SearchIconRed from '../../../assests/icons/search-icon-red.png'
import { Menu } from '@mui/material';
import CaseList from './CaseList';
import './EAPCasesSidebar.scss';
import Loader from '../../Loader/Loader';
import { PRTUserCasesType, storageKeys, userType, Tabs } from '../../../../utils/constants';

interface EAPSideBarProps {
  onCaseTypeTabChange: (caseType: string) => void;
  tabs: {};
  cases : any[];
  activeCase: any;
  setActiveCase: (activeCase: any) => void;
  setActiveCaseInfo: (caseInfo: any) => void;
  casesTypeName: string;
  setCasesTypeName: (name: string) => void;
  setCaseLoading: (loading: boolean) => void;
  setCaseClaimed?: (claim: boolean) => void;
  setFileNo: (file: number) => void;
  setNoData?: (data: boolean) => void;
  caseLoading: boolean;
  caseStatus: string;
  setCaseReopen?: (open: boolean) => void;
  setLoading: (loading: boolean) => void;
}

const EAPCaseSideBar: React.FC<EAPSideBarProps> = ({
  tabs,
  cases,
  activeCase,
  setActiveCase,
  setActiveCaseInfo,
  setCasesTypeName,
  casesTypeName,
  setCaseLoading,
  setCaseClaimed,
  setFileNo,
  setNoData,
  setCaseReopen,
  onCaseTypeTabChange,
  setLoading
}) => {
  const { globalErrorHandler }: any = useContext(globalContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openList, setOpenList] = useState<boolean>(false);
  const [caseList, setCaseList] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false)
  const [noCase, setNoCase] = useState<boolean>(false)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
  const tabName: Tabs[] = Object.values(tabs)

  useEffect(() => {
    if (searchBoxRef.current) {
      setMenuWidth(searchBoxRef.current.offsetWidth);
    }
  }, [searchBoxRef.current]);

  const getCaseData = async (id: any): Promise<void> => {
    setCaseLoading(true);
    try {
      const response = await getEAPCaseFileData(id);
      setActiveCaseInfo(response.data.data);
      setCaseLoading(false);
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const handleCaseType = async (case_type: any): Promise<void> => {
    if (casesTypeName === case_type.name) return
    setSearchTerm("")
    try {
      setLoading(true) 
      onCaseTypeTabChange(case_type.value)
      setCasesTypeName(case_type.label);
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setLoader(false);
      setCaseLoading(false)
    }
  };

  const handleCase = (id: any, fileNo: number) => {
    if (activeCase === id) return
    setFileNo(fileNo);
    setActiveCase(id);
    getCaseData(fileNo);
  };

  const searchCase = async (): Promise<void> => {
    if (!searchTerm) return
    setLoader(true);
    const type = casesTypeName === PRTUserCasesType.MY_CASES.label ? "all" : "closed"
    try {
      if (userTypeName === userType.PRT_MANAGER) {
        const response = await searchCasePRTManager(searchTerm);
        setCaseList(response.data.data.cases);
      } else {
        const response = await searchEapxCase(searchTerm, type);
        setCaseList(response.data.data.cases);
      }
      setOpenList(true);
      setLoader(false);
    } catch (error) {
      setLoader(false)
      setOpenList(true);
      setNoCase(true)
      globalErrorHandler(error);
      setTimeout(() => {
        setNoCase(false)
        setOpenList(false)
      }, 5000)
    }
  };

  const handleClose = () => {
    setOpenList(false)
  }

  return (
    <div className='eap-cases-sidebar-component' ref={searchBoxRef}>
      <div className='search-box' id='case-list' aria-haspopup="true" aria-controls={openList ? 'case-list' : undefined}
        aria-expanded={openList ? 'true' : undefined}>
        <input
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <img src={userTypeName === userType.PRT_USER ? SearchIconGreen : SearchIconRed} alt='search' className='search-icon' onClick={searchCase} />
      </div>
      {openList && (
        <Menu
          id="case-list"
          open={openList}
          onClose={handleClose}
          anchorEl={searchBoxRef.current}
          style={{ maxHeight: 400, minWidth: menuWidth, maxWidth: menuWidth, width: "100%" }}
          MenuListProps={{
            'aria-labelledby': 'case-list',
          }}
        >
          <CaseList
            caseList={caseList}
            setCaseClaimed={setCaseClaimed || (() => { })}
            setCaseLoading={setCaseLoading}
            setSearchTerm={setSearchTerm}
            setOpenList={setOpenList}
            noCase={noCase}
            searchTerm={searchTerm}
            casesTypeName={casesTypeName}
            setCasesTypeName={setCasesTypeName}
            setCaseReopen={setCaseReopen || (() => { })}
          />
        </Menu>
      )}
      <div className='case-options'>
        {
          tabName.map((item: Tabs, index: number) => {
            return <span
              className={casesTypeName === item.label ? "active-case-type" : 'case-type'}
              key={"opt" + index}
              onClick={() => handleCaseType(item)}
            >
              {item.label}
            </span>
          })
        }
      </div>
      <span className='case-title'>{"Case number"}</span>
      {
        loader ? <div className='loader-box'><Loader /></div> : <>
          {cases.length ? (
            <div className='case-list'>
              {cases.map((item: any, index: number) => {
                return (
                  <div
                    className={
                      activeCase === item.eapx_case_uuid
                        ? (userTypeName === userType.PRT_USER ? "active-case-green" : "active-case-red")
                        : "case-div"
                    }
                    key={"case" + index}
                    onClick={() => handleCase(item.eapx_case_uuid, item.eapx_file_number)}
                  >
                    <div className='info-section'>
                      <div className='section-one'>
                        <span className='case-id'>#{item.eapx_file_number?.toString()}</span>
                        <div className='identity-section'>
                          <span className='user-identity'>{item.company_name}</span>
                        </div>
                      </div>
                      <div className="date-time-section">
                        <span className='last-modified'>{getFormatDateString(item.eapx_last_modified).formattedDate}</span>
                        <span className='last-modified'>{extractTimeFromString(item.eapx_last_modified)}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className='no-cases'>{userTypeName === userType.PRT_USER ? "No cases claimed" : "No cases found"}</div>
          )} </>
      }
    </div>
  );
};

export default React.memo(EAPCaseSideBar);
