import WorkLifeBlock from "../../../../routes/WorkLifePageBlock";
import FixedHeaderUserLayout from "../../../layout/FixedHeaderUserLayout";
import SuccessMessage from "./SuccessMessage";

const index: React.FC = () => {
  return <>
  <WorkLifeBlock />
  <FixedHeaderUserLayout children={<SuccessMessage />} />
  </>;
};

export default index;
