import React from 'react'
import './MentalHeatlhActionCard.scss'
import './LongCradCommonstyles.scss'
import ContentDescriptionCard from './ContentDescriptionCard'
import { useNavigate } from 'react-router-dom';

interface CardProps {
    title: string;
    image: string;
    button: boolean;
    content: string;
    drDetails: string;
    showProgressBar: boolean | number;
    resume?: boolean;
    exercise: number;
    videos: number;
    totalMins : number
}

const ActionCard: React.FC<CardProps> = ({ title, image, content, button, drDetails, showProgressBar, resume, exercise, videos,totalMins }) => {
    const navigate = useNavigate()
    const handleCourseSelection = () => {
        navigate('/mental-health-course')
    }
    
    return (
        <div className='action-card-component' onClick={handleCourseSelection}>
            <img className='left-side-img' src={image} alt='module-img' />
            <ContentDescriptionCard
                title={title}
                button={button}
                content={content}
                drDetails={drDetails}
                exercise={exercise}
                videos={videos}
                totalMins={totalMins}
                showProgressBar={showProgressBar}
                resume={resume} />
        </div>
    )
}

export default ActionCard