
export const Questions = [
    { question: "What is EAP?", answer: "‘EAP’ stands for Employee Assistance Program. CCA@YourService, your EAP, is a free, confidential benefit available to you and your family members. It provides useful information, practical support and professional counseling on a wide range of work, family and personal issues." },
    { question: "Is it really confidential?", answer: "Yes, it is. When you contact the EAP, the only information required from you is the name of your employer. Your employer will receive utilization statistics on the number of people using the EAP, but no personal, identifying information will be disclosed. No one will know that you have contacted the service unless you choose to disclose this information. CCA is bound by professional standards protecting confidentiality and preventing the disclosure of details that would identify individuals who have contacted the EAP." },
    { question: "Who provides the service?", answer: "The service is run by CCA, an expert provider of emotional wellbeing and work-life services, which is completely independent from your employer." },
    { question: "When is the EAP available?", answer: "You can contact the service 24 hours a day, 7 days a week, 365 days a year. Phones are always answered live by master’s-level clinicians." },
    { question: "Is it really free?", answer: "Yes, the service is paid for by your employer. For the duration of your tenure at your organization, and up to three months post-employment, you won’t have to pay for any information or support that you receive directly from the EAP." },
    { question: "Who can use the EAP?", answer: "All the benefits and convenience you enjoy from the EAP are available to your family and those you care about. This includes a spouse or significant other, children, parents, siblings, cousins, roommates—anyone who has an impact on your life." },
    { question: "What type of support is available?", answer: "There is a range of support that the service can provide, depending on your unique needs:", list: ["Practical information and support", "Professional resources and fact sheets", "Referrals to services in your local area", "Free short-term counseling sessions", "Referrals to long-term or specialized care", "Online support through our EAP website"] },
    { question: "What’s on the EAP website?", answer: "Our comprehensive website has an extensive range of content including 2,000+ articles, searchable databases and interactive tools." },
    {
        question: "What kinds of things can the EAP assist with?", answer: "You can contact the EAP for support on any issue that matters to you and those you care about, including these topics and much more:", topics: [
            { title: 'PERSONAL', list: ['Emotional well-being', 'Managing stress', 'Anxiety and depression', 'Disability and illness', 'Substance and alcohol misuse', 'Addictions and compulsive behaviors', 'Personal development'] },
            { title: 'LIFE EVENTS', list: ['Pregnancy, adoption, and new baby', 'Marriage and cohabitation', 'Separation and divorce', 'Bereavement and loss', 'Retirement'] },
            { title: 'WORK', list: ['Work-life balance', 'Bullying and harassment', 'Workplace pressure', 'Managing change', 'Workplace relationships', 'Career development'] },
            { title: 'FAMILY &amp; RELATIONSHIPS', list: ['Childcare', 'Education', 'Elder care', 'Juggling work and family life', 'Caring for others', 'Domestic violence or abuse'] },
            { title: 'DAILY LIVING', list: ['Pet care', 'Health and wellness', 'Debt and money management', 'Moving and relocation', 'Community resources', 'Legal consultation', 'Disaster preparedness and recovery'] }
        ]
    },
    { question: "What are the benefits to me?", answer: "Your EAP can support you through difficult times, if you need extra support, feel alone or would like an independent ear. It can also save you precious time and legwork when dealing with every-day, practical issues. Overall, using the EAP should help reduce stress and anxiety, improve your well-being and free you up to focus on other things.       " },
]   