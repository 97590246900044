export interface ValidationError {
    message: string;
    field: string;
  }
  
  export const validate = (formData: { firstname: string; lastname: string; email: string }): ValidationError | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!formData.firstname) {
      return { message: "First name is required.", field: "firstname" };
    }
    if (!formData.lastname) {
      return { message: "Last name is required.", field: "lastname" };
    }
    if (!formData.email) {
      return { message: "Email address is required.", field: "email" };
    } else if (!emailRegex.test(formData.email)) {
      return { message: "Please enter a valid email address.", field: "email" };
    }
    return null; 
  };
  