import './ModuleSidebar.scss'
import LeftArrowIcon from '../../../../component/assests/icons/left-arrow.png'
import { CBTCourseList } from '../../../../utils/member-portal/CBTCourseList'
import CourseModule from './course-module/CourseModule'
import { useNavigate } from 'react-router-dom'
import { CourseItem } from '../../../../interfaces/MemberPortal'

const ModuleSidebar = () => {
    const navigate = useNavigate();
    return (
        <div className='module-sidebar-component'>
            <div className='navigation-bar' onClick={() => navigate(-1)}>
                <img src={LeftArrowIcon} alt='left-arrow' className='navigation-icon' />
                <span className='navigation-title'>Back to module</span>
            </div>
            <div className='modules-list'>
                {
                    CBTCourseList.map((item : CourseItem, index) => {
                        return <CourseModule list={item} key={'module' + index}  />
                    })
                }
            </div>
        </div >
    )
}

export default ModuleSidebar