import React, { useState } from 'react'
import './ExerciseModule.scss'
import TitleDescription from '../TitleDescription/TitleDescription'
import IncrementDecrementButton from '../Counter/IncrementDecrementButton';
import DateAndTimeInput from '../Input/DateAndTimeInput';
import ScheduleTable from '../Table/ScheduleTable';
import ExerciseInput from '../Input/ExerciseInput';
import JournalEntry from '../JournalEntry/JournalEntry';
import { JournalEntryQuestions } from '../../../interfaces/MemberPortal';
import { ChallengeTwoQuestions } from '../../../utils/member-portal/constant';
import SmallGreenButton from '../Button/SmallGreenButton';
import QuestionnaireQues from '../Questionnaire/QuestionnaireQues';
import QuestionnaireScore from '../Questionnaire/QuestionnaireScore';
import VideoModule from './VideoViewer';

const ExerciseModule: React.FC = () => {

    const [challenge, setChallenge] = useState<number>(0)

    const [entries, setEntries] = useState<JournalEntryQuestions[]>([
        {
            journalEntry: { date: "3/15/2024", time: "5:30 PM" },
            situation: "A friend walked by without saying hello to me",
            thoughts: "Maybe they don’t like me anymore. Maybe my other friends don’t like me anymore.",
            depression: 5,
            result: "Felt sad. Withdrew from the situation. Went home and watched sad shows on Netflix."
        }
    ])

    const handleAddEntry = () => {
        const newEntry: JournalEntryQuestions = {
            journalEntry: { date: "", time: "" },
            situation: "",
            thoughts: "",
            depression: 0,
            result: ""
        };
        setEntries((prevEntries: JournalEntryQuestions[]) => {
            const updatedEntries = [...prevEntries, newEntry];
            return updatedEntries;
        });
    };

    const handleDeleteEntry = (index: number) => {
        setEntries(entries.filter((_, i) => i !== index));
    };

    return (
        <div className='exercise-module-component'>
            {
                challenge === 0 && <VideoModule
                    title='The Lemon'
                    description='The "lemon" technique is a guided visualization exercise used to demonstrate how thoughts can influence physical reactions. Participants are asked to imagine holding, smelling, and tasting a lemon in vivid detail, often leading to a physical response like salivation. This exercise helps illustrate the power of the mind-body connection and is often used in therapies like CBT to show how mental imagery and thoughts can affect emotions and physical sensations.'
                    onClick={() => setChallenge(challenge + 1)} />
            }
            {
                challenge === 1 && <>
                    <TitleDescription
                        title={"Reflecting on your self talk"}
                        description={"For the next 5 days can you notice your self talk? Just note it. Does it serve you?Each day have a user track 1 (or more) Negative thoughts that they had when feeing sad/down, stressed or anxious. "}
                        onClick={() => setChallenge(challenge + 1)} />
                    <JournalEntry entries={entries} handleDeleteEntry={handleDeleteEntry} />
                    <button className='add-button' onClick={handleAddEntry}>+ Add a new entry</button></>
            }
            {
                challenge === 2 && <>
                    <TitleDescription
                        title={"Challenging your self-thought"}
                        description={"Refer to one self-thought from the last exercise and reflect with the provided socratic questionings. Some questions will fit better than others, so choose to answer the questions that best reflect your self-thou"}
                        onClick={() => setChallenge(challenge + 1)} />
                    {
                        ChallengeTwoQuestions.map((title, index) => {
                            return <ExerciseInput title={title} key={index + "title"} />
                        })
                    }
                    <SmallGreenButton name={"Submit"} />
                </>
            }
            {
                challenge === 3 && <>
                    <TitleDescription
                        title={"Weekly activity log - with mood tracker"}
                        description={"For the next 7 days can you notice your self talk? Just note it. Does it serve you? Each day have a user track 1 (or more) Negative thoughts that they had when feeing sad/down, stressed or anxious."}
                        onClick={() => setChallenge(challenge + 1)} />
                    <DateAndTimeInput hideButton={false} title='Mood entry' />
                    <ExerciseInput title='Activity' />
                    <IncrementDecrementButton title='Mood rating (1-10):' />
                    <SmallGreenButton name='Submit' />
                    <ScheduleTable />
                </>
            }
            {
                challenge === 4 && <QuestionnaireQues onClick={() => setChallenge(challenge + 1)} />
            }
            {
                challenge === 5 && <QuestionnaireScore />
            }
        </div>
    )
}

export default ExerciseModule