import React from 'react'

interface ButtonProps {
    name : string
    onClick?: () => void;
}

const SmallGreenButton: React.FC<ButtonProps> = ({ name, onClick }) => {
    return (
        <div className='green-start-resume-btn' onClick={onClick}>{name}</div>
    )
}

export default SmallGreenButton