import React from 'react'
import './TitleDescription.scss'

interface Props {
    title: string;
    description: string;
    onClick?: () => void;
}

const TitleDescription: React.FC<Props> = ({ title, description, onClick }) => {
    return (
        <div className='intro-content-component'>
            <div className='intro-title-row'>
                <span className='module-common-title'>{title}</span>
                <button className='green-start-resume-btn' onClick={onClick}>Next</button>
            </div>
            <p className='module-desciption'>{description}</p>
        </div>
    )
}

export default TitleDescription