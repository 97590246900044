import './HeroGridImages.scss'
import ExporeTopicImg from '../component/assests/images/explore-topic.png'
import AccessSupport from '../component/assests/images/access-support.png'
import LearnCCA from '../component/assests/images/learn-abt-cca.png'
import LearnCCASmall from '../component/assests/images/leran-abt-small.png'

const HeroGridImages = () => {
  return (
    <div className='hero-grid-component'>
        <img src={ExporeTopicImg} className='grid-image' />
        <img src={AccessSupport} className='grid-image' />
        <img src={LearnCCA} className='grid-image' />
        <img src={LearnCCASmall} className='grid-image-response' />
    </div>
  )
}

export default HeroGridImages