import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import ExploreTopics from "./ExploreTopics";

const index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<ExploreTopics />} />
  );
};

export default index;
