import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import Login from "./Login";

const Index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<Login />} />
  );
};

export default Index;
