import WorkLifeBlock from "../../../../routes/WorkLifePageBlock";
import FixedHeaderUserLayout from "../../../layout/FixedHeaderUserLayout";
import RequestForm from "./RequestForm"

const index: React.FC = () => {
  return <>
    <WorkLifeBlock />
    <FixedHeaderUserLayout children={<RequestForm />} />
  </>;
};

export default index;
