import './ExpandableMenu.scss'
import { useContext, useState } from 'react'
import { ApiCareSquadCategory } from '../../../interfaces/MemberPortal'
import ExpandMenu from '../../assests/icons/expand-menu.png'
import globalContext from '../../../context/globalContext'
import { capitalizeFirstLetter } from '../../../utils/helperFunctions'

const ExpandableMenu = () => {
  const [isOptionHover, setIsOptionHover] = useState<boolean>(false)
  const [hoveredMenu, setHoveredMenu] = useState<number | null>(null)
  const [hoveredMenuOpt, setHoveredMenuOpt] = useState<number | null>(null)

  const { categories, setIsMenuExpanded }: any = useContext(globalContext)

  const handleMouseEnter = (menu: number, item: number) => {
    setHoveredMenu(menu)
    setHoveredMenuOpt(item)
    setIsOptionHover(true)
  }

  return (
    <div className='expand-menu-component' onMouseLeave={() => setIsMenuExpanded(false)} >
      <div className='menu-grid'>
        {
          categories.map((item: ApiCareSquadCategory, menuIndex: number) => {
            const subCategory = item.meta_data
            if (!item.meta_data.length) return
            return (<div className='menu-items' key={"category" + menuIndex}>
              <div className='sm-menu'>
                <span className='title'>{item.label}</span>
                <img src={ExpandMenu} alt='expand-menu' className='expand-menu-icon' />
              </div>
              {subCategory.map((topic, index) => {
                return (
                  <div onMouseEnter={() => handleMouseEnter(menuIndex, index)} className='menu-box' key={"subCat" + menuIndex}>
                    <div className='dot-options'>
                      <span className='dots-container'>
                        {
                          hoveredMenu === menuIndex && hoveredMenuOpt === index && isOptionHover
                            ? <span className='dots visible'></span>
                            : <span className='dots hidden'></span>
                        }
                      </span>
                      <span className='options'>{capitalizeFirstLetter(topic.name)}</span>
                    </div>
                  </div>
                )
              })}
            </div>)
          })
        }
      </div>
    </div>
  )
}

export default ExpandableMenu