import React from 'react'
import './QuestionnaireQues.scss'
import { Questionnaires } from '../../../utils/member-portal/constant'
import DropDownMenu from '../Input/DropDownMenu'
import SmallGreenButton from '../Button/SmallGreenButton'
import QuestionnarieTitleDesc from './QuestionnarieTitleDesc'

interface QuestionProps {
    onClick: () => void;
}

const QuestionnaireQues: React.FC<QuestionProps> = ({ onClick }) => {
    return (
        <div className='questionnaire-ques-component'>
            <QuestionnarieTitleDesc
                title='GAD-7 Anxiety'
                description='Over the last 2 weeks, how often have you been bothered by the following problems?' />
            {
                Questionnaires.map((quest, index) => {
                    return <DropDownMenu question={quest} key={index + "que"} />
                })
            }
            <SmallGreenButton name='Submit' onClick={onClick} />
        </div>
    )
}

export default QuestionnaireQues 