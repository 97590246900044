import React, { useState } from 'react'
import './CourseSection.scss'
import DropDownIcon from '../../../component/assests/icons/black-down-arrow.png'
import UpArrowIcon from '../../../component/assests/icons/black-up-arrow.png'
import { CourseItem } from '../../../interfaces/MemberPortal'
import CourseListItem from './CourseListItem'
import { Link } from 'react-router-dom'

interface Props {
    CBTCourseList: CourseItem[];
}

const CourseSection: React.FC<Props> = ({ CBTCourseList }) => {
    const [collapsedSections, setCollapsedSections] = useState<Record<number, boolean>>({});
    const toggleSectionCollapse = (index: number) => {
        setCollapsedSections((prevCollapsed) => ({
            ...prevCollapsed,
            [index]: !prevCollapsed[index],
        }));
    }
    return (
        <div>
            {
                CBTCourseList.map((item, index) => {
                    return <div className='course-section-component'
                        key={index + "course"}
                        onClick={() => toggleSectionCollapse(index)}>
                        <div className='section-title-row'>
                            <div className='section-icon-box'>
                                <img src={collapsedSections[index] ? UpArrowIcon : DropDownIcon} alt='collased' />
                                <span className='section-title'>{item.name}</span>
                            </div>
                            <Link to={item.path} className='no-underline'> <button className='green-start-resume-btn'>Resume</button></Link>
                        </div>
                        <div className='course-list'>
                            {
                                collapsedSections[index] && item.details.map((item, index) => {
                                    return <CourseListItem title={item.title} image={item.image} duration={item.duration} inProgress={item.inProgress} key={index + "item"} />
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default CourseSection