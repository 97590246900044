import './PopularTopics.scss'
import Topic1 from '../component/assests/images/topic1.png'
import Topic2 from '../component/assests/images/topic2.png'
import Topic3 from '../component/assests/images/topic3.png'
import BookIcon from '../component/assests/icons/book.png'
import SlackIcon from '../component/assests/icons/slack.png'
import AudioIcon from '../component/assests/icons/audio.png'
import PlayIcon from '../component/assests/icons/play.png'
import RightArraow from '../component/assests/icons/right-side-arrow.png'
import TopicCard from '../component/common/Cards/TopicCard'
import { useNavigate } from 'react-router-dom'

const PopularTopics = () => {

    const navigation = useNavigate()
    const TopicOne = [{ title: "Coping With Stress", src: BookIcon },
    { title: "Intro to CBT", src: SlackIcon },
    { title: "The Eight Dimensions of Wellness", src: PlayIcon },
    { title: "Find Services and Treatment", src: BookIcon }];
    const TopicTwo = [{ title: "Relaxation Techniques", src: BookIcon },
    { title: "Go with the flow", src: AudioIcon },
    { title: "Go with the flow", src: PlayIcon },
    { title: "Positive Emotions and Your Health", src: BookIcon }];;
    const TopicThree = [{ title: "My Health Finder Tool", src: BookIcon },
    { title: "Get Your Flu Vaccine", src: AudioIcon },
    { title: "Show Your Heart Some Love!", src: BookIcon },
    { title: "Weather Extremes and Your Health", src: BookIcon }];

    return (
        <div className='popular-topics-component'>
            <div className='titlebar'>
                <div className='topic-title'>{"Popular topics"}</div>
                <div className='more-topics' onClick={() => navigation('/explore-topics')}>
                    <div className='arrow-text'>{"Explore more topics"}</div>
                    <img src={RightArraow} alt='right-arraow' />
                </div>
            </div>
            <TopicCard title='Mental Health' image={Topic1} menuItems={TopicOne} />
            <TopicCard title='Mindfulness & Meditation' image={Topic2} menuItems={TopicTwo} />
            <TopicCard title='General Health' image={Topic3} menuItems={TopicThree} />
        </div>
    )
}

export default PopularTopics