import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import FAQ from "./FAQ";

const Index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<FAQ />} />
  );
};

export default Index;
