import React, { useState } from 'react'
import './IncrementDecrementButton.scss'

interface Props {
  title?: string;
}

const IncrementDecrementButton: React.FC<Props> = ({ title }) => {
  const [count, setCount] = useState(0);

  const increment = () => setCount(count < 10 ? count + 1 : count);
  const decrement = () => setCount(count > 0 ? count - 1 : 0);

  return (
    <div className='increment-decrement-component'>
      <span className='input-title'>{title}</span>
      <div className='combo-button'>
        <button onClick={decrement} className="button decrement">-</button>
        <span className="count">{count}</span>
        <button onClick={increment} className="button increment">+</button>
      </div>
    </div>
  )
}

export default IncrementDecrementButton