import React from 'react';
import './ModuleInterface.scss'
import ModuleSidebar from '../SideTabs/module-sidebar/ModuleSidebar'

interface ModuleProps {
  content: React.ReactNode
}

const ModuleInterface: React.FC<ModuleProps> = ({ content }) => {
  return (
    <div className='module-interface-component'>
      <div className='sidebar-grid sidebar-section'><ModuleSidebar /></div>
      <div className='module-content-grid'>{content}</div>
    </div>
  )
}

export default ModuleInterface