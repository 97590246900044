import React, { useEffect, useRef, useState } from 'react';
import MuxPlayer from "@mux/mux-player-react";
import TitleDescription from '../TitleDescription/TitleDescription';

interface ModuleProps {
    title: string;
    description: string;
    onClick: () => void;
}

const VideoModule: React.FC<ModuleProps> = ({ title, description, onClick }) => {
    const playerRef = useRef<any | null>(null);
    const [playbackPosition, setPlaybackPosition] = useState<number>(0);

    useEffect(() => {
        const savedPosition = localStorage.getItem('playbackPosition');
        if (savedPosition) {
            setPlaybackPosition(parseFloat(savedPosition));
        }
    }, []);

    const handlePause = () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.currentTime;
            localStorage.setItem('playbackPosition', currentTime.toString());
        }
    };

    const handleEnded = () => {
        localStorage.removeItem('playbackPosition');
    };

    useEffect(() => {
        if (playerRef.current && playbackPosition > 0) {
            playerRef.current.currentTime = playbackPosition;
        }
    }, [playbackPosition]);

    return (
        <div className='module-content'>
            <MuxPlayer
                ref={playerRef}
                playbackId="EcHgOK9coz5K4rjSwOkoE7Y7O01201YMIC200RI6lNxnhs"
                onPause={handlePause}
                onEnded={handleEnded}
                // autoPlay
            />
            <TitleDescription title={title} description={description} onClick={onClick} />
        </div>
    )
}

export default VideoModule