import React from 'react'
import './QuestionnaireScore.scss'
import QuestionnarieTitleDesc from './QuestionnarieTitleDesc'
import SmallGreenButton from '../Button/SmallGreenButton'
import ActionCard from '../Cards/MentalHealthActionCard'
import StressImg from '../../assests/images/topic2.png'

const QuestionnaireScore : React.FC = () => {
  return (
    <div className='questionnaire-score-component'>
        <QuestionnarieTitleDesc 
            title='GAD-7 Anxiety'
            description='Your GAD-7 results will determine the severity of your anxiety and future advisements for modules to take next. '/>
        <span className='input-title'>Your GAD-7 Score is</span>
        <span className='module-common-title'>15, Moderate Anxiety</span>
        <span className='input-title margin-title'>RECOMMENDED MODULE:</span>
        <div className='module-box'>
            <ActionCard 
                title='Stress and Anxiety' 
                drDetails='Curated by Dr. Andrew B. Schmidt'
                content='This course teaches participants how to identify and manage stress and anxiety through practical techniques. It covers recognizing triggers, addressing anxious thoughts, exposure therapy, and reducing avoidance behaviors, along with guided breathing and relaxation exercises to build better coping skills.'
                button={false}
                image={StressImg}
                showProgressBar={false}
                exercise={2}
                totalMins={25}
                videos={5} />
        </div>
        <SmallGreenButton name='Finish Module ' />
    </div>
  )
}

export default QuestionnaireScore